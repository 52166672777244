* {
  margin: 0;
  padding: 0;
}

.icon_loading {
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
}

.spin_loading {
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
}

.loading_box {
  width: 30px;
  height: 30px;
}

.drawer_close {
  color: #637bfd;
  font-size: 18px;
  text-align: right;
  margin-top: -10px;
  margin-right: -10px;
}

.modal_close {
  color: #637bfd;
  font-size: 22px;
  text-align: right;
  margin-top: -20px;
  margin-right: -15px;
}

.primary_color {
  background-color: #001529;
}