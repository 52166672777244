.container {
  //background-color: #000000;
}

.layout {
  width: 100%;
  margin: 0 auto;
  //background-color: #000000;
  color: #FFFFFF;
  flex: 1;
  height: 100vh;
}

.header {
  height: 60px;
  //margin-top: 27px;
  padding-left: 79pt;
  padding-right: 79pt;
  display: flex;
  align-items: center;
  //background-color: #000000;
  justify-content: space-around;

  .logo {
    width: 127px;
    margin-top: 20px;
    justify-items: flex-start;
  }

  .menu_link_box {
    list-style: none;
    height: 60px;
    display: flex;
    flex: 1;

    .menu_link_item {
      margin-left: 70pt;
      width: 40pt;
      font-size: 15px;
      color: #0ad06b;
    }
  }

  .buy_button {
    width: 121px;
    height: 45px;
    background-color: #0ad06b;
    font-weight: 500;
    color: #060606;
    cursor: pointer;
    text-align: center;
    line-height: 45px;
    border-radius: 10px;
    justify-content: flex-end;
  }

}

.content {
  width: 100%;
  padding: 10px;

  .form {
    margin-top: 30px;


    .commit_button {
      width: 120px;
      height: 45px;
      margin-left: 28.5%;
      background-color: #001529;
      font-weight: 500;
      color: #FFFFFF;
      cursor: pointer;
      text-align: center;
      border-radius: 10px;
      justify-content: flex-end;
    }
  }
}


