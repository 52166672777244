.container {
  background-color: #000000;
}

.layout {
  width: 1024pt;
  margin: 0 auto;
  background-color: #000000;
  color: #FFFFFF;
  flex: 1;
}

.header {
  height: 45px;
  margin-top: 27px;
  padding-left: 79pt;
  padding-right: 79pt;
  display: flex;
  align-items: center;
  background-color: #000000;
  justify-content: space-around;

  .logo {
    width: 127px;
    justify-items: flex-start;
  }

  .menu_link_box {
    list-style: none;
    height: 60px;
    display: flex;
    flex: 1;

    .menu_link_item {
      margin-left: 70pt;
      width: 40pt;
      font-size: 15px;
      color: #0ad06b;
    }
  }

  .buy_button {
    width: 121px;
    height: 45px;
    background-color: #0ad06b;
    font-weight: 500;
    color: #060606;
    cursor: pointer;
    text-align: center;
    line-height: 45px;
    border-radius: 10px;
    justify-content: flex-end;
  }

}

.content {
  width: 100%;
  height: auto;

  .download_section {
    margin-top: 80pt;
    margin-left: 88pt;
    margin-right: 66pt;
    display: flex;
    align-items: center;
    justify-content: center;

    .introduce {
      width: 376pt;

      .title {
        font-weight: bolder;
        font-size: 35px;
      }

      .details {
        width: 353pt;
        font-size: 18px;
        margin-top: 20pt;
      }

      .btn_download {
        width: 183px;
        height: 58px;
        margin-top: 10pt;
        cursor: pointer;
      }
    }

    .image {
      width: 415pt;
      margin-left: 29pt;
    }

  }

  .features_section {
    display: flex;
    margin-top: 30pt;
    flex-direction: column;
    align-items: center;
    background-color: #ecf5f1;

    .introduce {
      text-align: center;

      .advertising {
        font-size: 18px;
        color: #0ad06b;
        margin-top: 20pt;
      }

      .title {
        margin-top: 30pt;
        font-weight: 500;
        font-size: 35px;
        line-height: 40px;
        color: #343434;
      }

      .details {
        font-size: 18px;
        margin-top: 20pt;
        color: #343434;
      }

    }

    .image {
      width: 800pt;
      margin-top: 20pt;
    }

  }

  .speed_section {
    margin-top: 50pt;
    margin-left: 88pt;
    margin-right: 66pt;
    display: flex;
    align-items: center;
    justify-content: center;

    .introduce {
      width: 465pt;

      .advertising {
        font-size: 18px;
        color: #0ad06b;
        margin-top: 20pt;
      }

      .title {
        margin-top: 20pt;
        font-weight: bolder;
        font-size: 35px;
      }

      .details {
        width: 353pt;
        font-size: 18px;
        margin-top: 20pt;
      }

    }

    .image {
      width: 330pt;
      margin-left: 29pt;
    }

  }

  .privacy_section {
    margin-top: 50pt;
    margin-left: 88pt;
    margin-right: 66pt;
    padding-bottom: 50pt;
    display: flex;
    align-items: center;
    justify-content: center;

    .image {
      width: 330pt;
    }

    .introduce {
      width: 465pt;
      margin-left: 100pt;

      .advertising {
        font-size: 18px;
        color: #0ad06b;
        margin-top: 20pt;
      }

      .title {
        margin-top: 20pt;
        font-weight: bolder;
        font-size: 35px;
      }

      .details {
        width: 353pt;
        font-size: 18px;
        margin-top: 20pt;
      }

    }

  }
}


.support_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ecf5f1;

  .introduce {
    text-align: center;

    .title {
      margin-top: 50pt;
      font-weight: 500;
      font-size: 35px;
      line-height: 40px;
      color: #343434;
    }

    .details {
      font-size: 18px;
      margin-top: 20pt;
      color: #343434;
    }

    .email {
      font-weight: 500;
      font-size: 18px;
      margin-top: 20pt;
      color: #343434;
    }

    .email a {
      font-size: 18px;
      color: #0ad06b;
    }

  }

  .image {
    width: 453pt;
    margin-top: 20pt;
    margin-bottom: 20pt;
  }

}


.footer {
  margin-top: 20pt;
  color: #FFFFFF;

  .footer_links {
    display: flex;
    justify-content: flex-start;
    padding-top: 30px;
    font-weight: bolder;
    font-size: 14px;
    overflow: hidden;

    .link_column {
      width: 126pt;

      .link_header {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 20px;
        color: #FFFFFF;
      }

      .link_list {

        li {
          list-style-type: none;
          font-weight: 500;
          font-size: 14px;
          line-height: 25px;

          a {
            color: #777777;
          }
        }
      }

    }

  }

  .copyright {
    font-weight: 500;
    font-size: 14px;
    margin-top: 20pt;
    margin-bottom: 20pt;
    text-align: center;
    color: #777777;
  }
}


/* iPad */
@media only screen and (max-width: 768px) {
  .container {
    height: 890%;
  }
  .content_container {
    width: 760px;
    margin: 0 0 0 -380px;
  }
  .header {
    width: 100%;
    height: 55px;
  }

}
